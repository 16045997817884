import React, { useContext } from "react";
import { 
  stories, 
  products
} from "../KioskUtilities";
import HomeButton from "../HomeButton";
const KioskDashboard = ({ }) => {
  
  const renderHeader = () => {
    return (
      <div className="mt-[70px] bg-[#26065D] w-full h-936px flex rounded-ds p-[80px] flex items-center">
        <div className="w-2/3">
          <div class="text-white text-[37.27px] font-light font-['DS Indigo Nova AA'] leading-[45px] pb-[20px]">
            Modernize your banking operations with Docusign IAM
          </div>
          <div class=" text-white text-base font-normal font-['DS Indigo Nova AA']">
            From account opening, to asset transfers, or loan origination - nothing gets done without an agreement. Docusign IAM modernizes you agreement process, helping you to streamline operations and reduce risk - while delivering a world class customer experience.
          </div>
        </div>
        <div className="w-1/3 pl-[80px]">
          <img src="/images/page.png" />

        </div>
      </div>     
    )
  }

  const renderProducts = () => {
    return (
      <div>
        {renderSectionTitle("Featured products")}
        <div className="w-full flex">
          {products.map(x=>
            <a
              href={`/products/${x.id}`}
              className={`rounded-ds sg-product sg-product-${x.id}`}
              style={{
                backgroundImage: x.dashboardImage ? `url(${x.dashboardImage})` : null,
                backgroundSize: "cover"
              }}
              >
              <div style={{zIndex: 3}} className="sg-product-title">
                {x.name}
              </div>

            </a>
          )}
        </div>
      </div>
    )
  }

  const renderCustomerStories = () => {
    return (
      <div className="">
        {renderSectionTitle("Customer stories")}
        <div className="w-full h-[611px] relative mt-[30px]">
          {stories.map(x=>
            <a 
              style={{
                backgroundImage: x.dashboardTile ? `url(${x.dashboardTile})` : null,
                backgroundSize: "cover"
              }}
              href={`/stories/${x.id}`}
              className={`rounded-ds sg-customer-story sg-customer-story-${x.id}`}>
            </a>
          )}
        </div>


      </div>
    )
  }


  const renderSectionTitle = (title) => {
    return (
      <>
      <div className="mt-[84px] mb-[28px] text-white text-[37px] font-light font-['DS Indigo Nova AA']">
        {title}
      </div>
      </>
    )
  }

  return (
    <div className="sg-ds-db24-kiosk-dashboard">
      {renderHeader()}
      {renderProducts()}
      {renderCustomerStories()}
    </div>
  );
};

export default KioskDashboard;