import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

export const useAppsignal = () => {
  const appsignal = new Appsignal({
    key: "ESB_APPSIGNAL_FRONTEND_API_KEY",
  });
  return { appsignal, ErrorBoundary };
};

export default useAppsignal;
