// React component mounting
import { mountReactComponents } from "./react_mounter";
// Import mounted (top-level) components here
import KioskApp from "@components/kiosk/KioskApp";

// Add components to the mountComponents list
// key and value should both match the component name
const mountComponents = {
  KioskApp: KioskApp,
};

mountReactComponents(mountComponents);

// Glob loader
//
// To avoid having to manually import top-level components, you can optionally
// use the glob loader. To do this, comment out the above loader code,
// and uncomment the code below.
//
// You will also need to install the eslint-plugin-glob-loader plugin:
// https://github.com/thomaschaaf/esbuild-plugin-import-glob
//
// WARN: This plugin is unmaintained. Use it and the glob loader at your
// own risk. It's working as of esbuild 0.17, but keep an eye on it after
// major esbuild version updates.
//
// import * as components from "./components/**/*";
// import { mountReactComponentsGlob } from "./react_mounter";
// const { default: componentsArray, filenames } = components;
// mountReactComponentsGlob(componentsArray.map(x=>x.default), filenames);
