import React, { useContext } from "react";

const HomeButton = () => {
  return (
    <div className="sg-db24-kiosk-return-home-button">
      <a href="/">
        <img
          className="h-[43px]"
          src="/images/home-button.svg"
        />
      </a>
    </div>
  )
}

export default HomeButton