import React, { useContext } from "react";
import { 
  stories, 
  products
} from "../KioskUtilities";
import { useParams } from "react-router-dom";
import HomeButton from "../HomeButton";
const StoryDashboard = ({ }) => {
  let { id } = useParams();
  const story = stories.find(x=> x.id == id)
  console.log(story);
  const renderHeader = () => {
    return (
      <div
        className="leading-[69px] w-full text-slate-900 text-[61.87px] font-light font-['DS Indigo Nova AA'] pt-[116px] pb-[60px] pr-[100px]"
      >
        {story.title}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div
        className="w-full flex items-center"
      >
        <div className="w-1/2 pr-[20px]">
          <div class=" text-slate-900 text-[23px] font-normal font-['DS Indigo Nova AA'] leading-9">
            {story.description}
          </div>
        </div>
        <div className="w-1/2 pl-[20px]">
          <div
            className="bg-violet-950 rounded-ds object-cover w-full h-[461px]"
            style={{
              backgroundImage: story.pageImage1 ? `url(${story.pageImage1})` : null,
              backgroundSize: "cover"
            }}
          />
        </div>

      </div>
    )
  }

  const renderMediaContent = () => {
    return (
      <div
        style={{
          backgroundImage: story.pageImage2 ? `url(${story.pageImage2})` : null,
          backgroundSize: "cover"
        }}
        class="mt-[114px] h-[448px] w-full  bg-stone-100 rounded-ds"
      >
      </div>
    )

  }

  const renderBlurbs = () => {
    return (
      <div>
        <div class="mt-[30px] mb-[10px] w-full text-white text-[37.27px] font-light font-['DS Indigo Nova AA']">
          {story.subhead2}
        </div>
        <div className="flex">
          {story.blurbs.map(x=>
            renderBlurb(x)
          )}
        </div>
      </div>
    )
  }


  const renderBlurb = (blurb) => {
    return (
      <div
        className="mr-30 w-1/3 inline-block pr-[20px]"
      >

        <div class="text-white text-[27px] font-normal font-['DS Indigo Nova AA']">
          {blurb.header}
        </div>
        <div class="mt-[15px] text-white text-[16.87px] font-normal font-['DS Indigo Nova AA']">
          {blurb.body}
        </div>

      </div>
    )
  }



  return (
    <div className="sg-ds-db24-kiosk-story-dashboard">
      {renderHeader()}
      {renderContent()}
      {renderMediaContent()}
      {renderBlurbs()}
      <HomeButton />
    </div>
  );
};

export default StoryDashboard;