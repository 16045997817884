import React, { useContext } from "react";

const KioskLayout = ({ children }) => {

  const dimensions = {
    width: 1080,
    height: 1920,
    headerHeight: 105,
    bodyHeight: 1080 - 105
  }

  const renderHeader = () => {
    return (
      <div className={`bg-white w-full h-[${dimensions.headerHeight}px] flex items-center justify-left px-[72px]`}
        style={{
          height: dimensions.headerHeight
        }}
      >
        <a href="/">
          <img
            className="h-[43px]"
            src="/images/ds-logo.png"
          />
        </a>
      </div>
    )
  }
  
  return (
    <div className="sg-ds-db24-kiosk pb-[80px]" style={{ width: dimensions.width, minHeight: dimensions.height }}>
      {renderHeader()}
      <div className={
        `
          h-[${dimensions.bodyHeight}px] w-full
          overflow-y-scroll
          px-[72px]
        `
      }>
        {children}
      </div>
    </div>
  );
};

export default KioskLayout;