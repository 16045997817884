import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import { useAppsignal } from "@shared/useAppsignal";
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro'
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime, Settings } from "luxon";
import KioskLayout from "./KioskLayout";
import KioskRouter from "./KioskRouter";
const KioskApp = ({ user }) => {
  const [locale, setLocale] = useState("en");
  const queryClient = new QueryClient();
  const { appsignal, ErrorBoundary } = useAppsignal();
  const urlBase = "/";
  const MUI_KEY = "d17c34eea831104e9aaa6a1fcb440234Tz04NDAxMixFPTE3MzkxNDQzODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
  LicenseInfo.setLicenseKey(MUI_KEY);

  useEffect(() => {
    Settings.defaultLocale = "en-us"
    Settings.defaultZoneName = "America/Toronto";
  },[])



  
  return (
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-us">
      <ErrorBoundary instance={appsignal}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <BrowserRouter basename={urlBase}>
              <KioskLayout>
                <KioskRouter/>
              </KioskLayout>
            </BrowserRouter>
          </StyledEngineProvider>
        </QueryClientProvider>
      </ErrorBoundary>
      </LocalizationProvider>
    </React.StrictMode>
  );
};

KioskApp.defaultProps = {
};

KioskApp.propTypes = {
};

export default KioskApp;
