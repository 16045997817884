import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Routes,
  Route,
} from "react-router-dom";
import NotFound from "./NotFound";
import KioskDashboard from "@kiosk/dashboard/KioskDashboard";
import StoryDashboard from "@kiosk/story/StoryDashboard";
import ProductDashboard from "@kiosk/product/ProductDashboard";
const KioskRouter = ({urlBase} ) => {
  return (
    <>
    <Routes>
      <Route path="/" element={<KioskDashboard/>}/>
      <Route path="/stories/:id" element={<StoryDashboard/>}/>
      <Route path="/products/:id" element={<ProductDashboard/>}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
    </>
  );
};

export default KioskRouter;