import React from "react";

const products = [
  {
    name: "Web Forms",
    id: 1,
    subhead: "Streamline data collection and accelerate signing",
    dashboardImage: "/images/wf4.png",
    pageImage: "/images/webforms/top3.png",
    featuresSubhead: "Web Forms features",
    description: (
      <ul>
        <li>Say goodbye to abandoned forms and lost conversions with Docusign Web Forms.</li>
        <li>Quickly build and deploy dynamic web forms -- no code necessary.</li>
        <li>Pre-populate data to accelerate agreement completion.</li>
        <li>Unlock the value of your data with actionable analytics.</li>
      </ul>
    ),
    features: [
      {
        name: "Accelerate agreement completion",
        description: "Streamline data collection and create agreements for signature with interactive forms.",
        image: "/images/webforms/accelerate.svg"
      },
      {
        name: "Deliver trusted experiences for your signers",
        description: "Deliver a simple, secure experience with a platform that's trusted by over a million customers worldwide.",
        image: "/images/webforms/trusted.svg"
      },
      {
        name: "Empower your team",
        description: "Arm your team with drag and drop capabilities and say goodbye to unnecessary delays and busywork.",
        image: "/images/webforms/empower.svg"
      }
    ],
    qrCodeHeader: "Learn more about Web Forms",
    qrCodeBody: "Make form filling and signing a breeze with Web Forms.",
    qrCodeImage: "/qr/webform.png"
  },
  {
    name: "Identify",
    id: 2,
    dashboardImage: "/images/ii4.png",
    subhead: "Improve security and compliance with intuitive identity verification",
    qrCodeHeader: "Learn more about Identify",
    qrCodeBody: <>Verify signer identity for your important&nbsp;agreements.</>,
    qrCodeImage: "/qr/identify.png",
    pageImage: "/images/identify/top2.png",
    featuresSubhead: "Identify features",
    description: (
      <ul>
        <li>
          Make sure signers are who they say they are with Docusign Identify.
        </li>
        <li>
          Ensure KYC/AML Compliance.
        </li>
        <li>
          Improve Customer Experience.
        </li>
        <li>
          Increase Conversions.
        </li>
      </ul>
    ),
    features: [
      {
        name: "Support compliance",
        description: "Help meet requirements like Know Your Customer (KYC), Anti-Money Laundering (AML) and regional regulations.",
        image: "/images/identify/support.svg"
      },
      {
        name: "Manage identification seamlessly",
        description: "Embed identity proofing and authentication into one connected Docusign eSignature experience.",
        image: "/images/identify/manage.svg"
      },
      {
        name: "Operate globally",
        description: "Access the industry’s largest network of identity verification and Trust Service Providers.",
        image: "/images/identify/operate.svg"
      }
    ]
  },
  {
    name: "Data Verification",
    id: 3,
    dashboardImage: "/images/dv4.png",
    subhead: "Verify signer-provided information instantaneously",
    description: "Automatically verify signer-provided information that is needed to complete agreements. With Data Verification, customers can connect Docusign eSignature with external applications, check personal and account information, and automatically validate before allowing the user to finish signing.",
    qrCodeHeader: "Learn more about Data Verification",
    qrCodeBody: "Verify signer-provided information instantaneously.",
    qrCodeImage: "/qr/dv.png",
    featuresSubhead: "Data Verification features",
    pageImage: "/images/datav/top.webp",
    features: [
      {
        name: "Accelerate turnaround time, minimize errors",
        description: <>Validate data real-time against trusted databases to reduce not-in-good-order documents with incomplete fields or&nbsp;errors.</>,
        image: "/images/datav/accelerate.webp"
      },
      {
        name: "Reduce risk with trusted data sources",
        description: "Maintain compliance with a comprehensive audit log that tracks envelope history and provides evidence of verification.",
        image: "/images/datav/if2.png"
      },
      {
        name: "Streamline signer experiences",
        description: "Confirm signer-provided data instantaneously, eliminating potential rework post-signature and boosting confidence in information accuracy.",
        image: "/images/datav/Streamline.webp"
      }
    ]
  }
]

const stories = [
  {
    name: "FineMark",
    title: "Docusign helps FineMark Bank evolve with its clients",
    id: 2,
    subhead: "Docusign helps FineMark Bank evolve with its clients",
    description: "FineMark has grown to more than 200 associates with bank assets totaling $2.5 billion. They credit much of their success to continuous innovation that puts the client first. By adopting a nimble, Salesforce-integrated CLM and eSignature solution, FineMark can cater to clients of all ages, preferences, and locations - while improving compliance.",
    dashboardTile: "/images/finemark2.png",
    
    pageImage1: "/images/customerstories/top-fine.png",
    pageImage2: "/images/customerstories/cq-finemark.png",
    subhead2: "Successes seen with Docusign",
    blurbs: [
      {
        header:"90%",
        body: <>Reduction in time to create<br/>trust packets.</>
      },
      {
        header:"100",
        body: <>Document templates created <br/>in just three weeks.</>
      },
    ]
  },
  {
    name: "WSECU Customer Story",
    title: "WSECU delivers seamless anywhere banking",
    id: 1,
    subhead: "Washington State Employees Credit Union Delivers Seamless Anywhere Banking",
    description: (<>
      WSECU struggled with paper processes and manual data entry, leading to a poor experience for both members and employees. From lengthy loan documentation requiring in-branch appointments, to cumbersome printing and scanning, to manual data entry, a broken agreement process held WSECU back. Using Docusign eSignature, WSECU turned 100+ paper forms into digital workflows— improving external and internal processes.
    </>),
    dashboardTile: "/images/wsecu2.png",
    pageImage1: "/images/customerstories/top-wsecu.png",
    pageImage2: "/images/customerstories/cq-wsecu.png",
    subhead2: "Successes seen with Docusign",
    blurbs: [
      {
        header:"100+",
        body: <>Paper forms transformed into<br/>digital workflows.</>
      },
      {
        header:"↓",
        body: <>Loan application times reduced<br/>from days/weeks to minutes.</>
      },
      {
        header:">75%",
        body: <>Faster processing for pre-authorized debit requests.</>
      }
    ]
  },

  {

    name: "Visions Federal Credit Union Customer Story",
    title: "Visions Federal Credit Union boosts security and trust in digital banking",
    id: 3,
    subhead: "Visions Federal Credit Union Boosts Security and Trust in Digital Banking",
    description: "Over the course of a decade-long partnership, Visions FCU has relied on Docusign to support their efforts to deliver a secure and friction-free lending experience for their members. Harnessing the power of capabilities like Docusign eSignature, ID Verification, and Monitor, Visions has cut turnaround time in half, while increasing digital interactions by 86% - helping drive a 500% increase in digital membership enrollment.",
    dashboardTile: "/images/visions2.png",
    pageImage1: "/images/customerstories/top-vis.png",
    pageImage2: "/images/customerstories/cq-vision.png",
    subhead2: "Successes seen with Docusign",
    blurbs: [
      {
        header:"2x",
        body: "Faster turnaround on agreements."
      },
      {
        header:"500%",
        body: "Increase in digital membership enrollment."
      },
      {
        header:"Hundreds",
        body: "Of member-facing forms digitized."
      }
    ]
  }
]

export { products, stories }