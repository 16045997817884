import React, { useContext } from "react";
import { 
  stories, 
  products
} from "../KioskUtilities";
import { useParams } from "react-router-dom";
import HomeButton from "../HomeButton";
const ProductDashboard = ({ }) => {
  let { id } = useParams();
  const product = products.find(x=> x.id == id)

  const renderContent = () => {
    return (
      <div
        className="w-full flex items-center mt-[129px]"
      >
        <div className="">
          <div class="pb-[12px] text-slate-900 text-[61.90px] font-light font-['DS Indigo Nova AA'] leading-[78.78px]">
            {product.name}
          </div>

          <div class="leading-[44px] pb-[15px] text-slate-900 text-[37.28px] font-light font-['DS Indigo Nova AA']">
            {product.subhead}
          </div>
          <div class="sg-product-description text-slate-900 text-2xl font-normal font-['DS Indigo Nova AA']">
            {product.description}
          </div>
        </div>
        <div className="w-[569px] shrink-0 pl-[20px]">
          <div
            className="bg-violet-950 rounded-ds object-cover w-full h-[651px]"
            style={{
              backgroundImage: product.pageImage ? `url(${product.pageImage})` : null,
              backgroundSize: "cover"
            }}
          />
        </div>

      </div>
    )
  }

  const renderFeatures = () => {
    return (
      <div className="pt-[119px]">
        <div class="w-[455.68px] h-[52.33px] text-white text-[37.28px] font-light font-['DS Indigo Nova AA']">
          {product.featuresSubhead}
        </div>
        
        <div className="flex mr-[-20px]">
          {product.features.map(x=>
            renderFeature(x)
          )}
        </div>
      </div>
    )
  }

  const renderFeature = (feature) => {
    return (
      <div
        className="mr-30 w-1/3 inline-block pr-[20px] mt-[25px]"
      >
        <div
          className="w-[297px] h-[297px] rounded-ds bg-stone-100"
          style={{
            backgroundImage: feature.image ? `url(${feature.image})` : null,
            backgroundSize: "cover"
          }}
        />
        <div class="pr-[20px] h-[59px] mt-[16px] text-white text-[22px] font-normal font-['DS Indigo Nova AA']">
          {feature.name}
        </div>
        <div class="mt-[15px] text-white text-[16.87px] font-normal font-['DS Indigo Nova AA']">
          {feature.description}
        </div>
      </div>
    )
  }

  const renderQrCode = () => {
    return (
      <div className="flex w-[60%] mt-[119px] items-center">
        <div
          className="flex-0 flex-shrink-0 flex-grow-0 w-[130px] h-[130px] text-slate-900 rounded-ds bg-stone-100 mr-[20px]"
          style={{
            backgroundImage: product.qrCodeImage ? `url(${product.qrCodeImage})` : null,
            backgroundSize: "85% 85%",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat"
          }}
        />
        <div>
          <div class="pb-[11px] text-white text-[22px] font-normal font-['DS Indigo Nova AA']">
            {product.qrCodeHeader}
          </div>
          <div class="text-white text-[17px] font-normal font-['DS Indigo Nova AA'] pr-[70px]">
            {product.qrCodeBody}
          </div>
        </div>
      </div>
    )
  }
  




  return (
    <div className="sg-ds-db24-kiosk-story-dashboard">
      {renderContent()}
      {renderFeatures()}
      {renderQrCode()}
      <HomeButton/>
    </div>
  );
};

export default ProductDashboard;