import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container, Typography, Box
} from '@mui/material';

const NotFound = ({urlBase} ) => {
  return (
    <Container maxWidth="xl">
      <Box>
        <Typography align="center" variant="h1" >404</Typography>
        <Typography align="center" variant="h4" >Page Not Found</Typography>
      </Box>
    </Container>
  );
};

export default NotFound;